import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import http from '../../utils/api'
import { message } from 'antd'

export interface TransactionError {
    message: string
}

export interface TransactionState {
    balance: any
    transaction: any
    transactions: Array<any>
    isBalanceLoading: boolean
    isTransactionLoading: boolean
    isTransactionsLoading: boolean
    balanceError: TransactionError
    transactionError: TransactionError
    transactionsError: TransactionError
}

export const initialState: TransactionState = {
    balance: null,
    transaction: null,
    transactions: [],
    isBalanceLoading: true,
    isTransactionLoading: true,
    isTransactionsLoading: true,
    balanceError: { message: '' },
    transactionError: { message: '' },
    transactionsError: { message: '' }
}

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        // FETCH BALANCE
        fetchBalanceLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isBalanceLoading = payload
        },
        fetchBalanceSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.balance = payload;
        },
        fetchBalanceFailed: (state, { payload }: PayloadAction<TransactionError>) => {
            state.balanceError = payload;
            state.balance = null;
        },
        // FETCH SINGLE TRANSACTION
        fetchTransactionLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isTransactionLoading = payload
            // state.transaction = null;
        },
        fetchTransactionSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.transaction = payload;
        },
        fetchTransactionFailed: (state, { payload }: PayloadAction<TransactionError>) => {
            state.transactionError = payload;
            state.transaction = null;
        },
        // FETCH ALL TRANSACTIONS
        fetchTransactionsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isTransactionsLoading = payload
        },
        fetchTransactionsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.transactions = payload;
        },
        fetchTransactionsFailed: (state, { payload }: PayloadAction<TransactionError>) => {
            state.transactionsError = payload;
        },
    },
})

export const { 
    fetchBalanceLoading, fetchBalanceSuccess, fetchBalanceFailed, 
    fetchTransactionLoading, fetchTransactionSuccess, fetchTransactionFailed, 
    fetchTransactionsLoading, fetchTransactionsSuccess, fetchTransactionsFailed, 
} = transactionsSlice.actions;
export const transactionSelector = (state: { transactions: TransactionState }) => state.transactions;
export default transactionsSlice.reducer;


/** Actions */

export const fetchBalance = (): AppThunk => async (dispatch) => {
    dispatch(fetchBalanceLoading(true))
    await http.get(`/laboratories/balance`)
        .then((res) => {
            const transaction= res?.data?.data;
            dispatch(fetchBalanceSuccess(transaction))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(fetchBalanceFailed(message));
        })
    dispatch(fetchBalanceLoading(false));
}

export const fetchSingleTransaction = (id: any): AppThunk => async (dispatch) => {
    dispatch(fetchTransactionLoading(true))
    await http.get(`/balance/transactions/${id}`)
        .then((res) => {
            const transaction= res?.data?.data;
            dispatch(fetchTransactionSuccess(transaction))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(fetchTransactionFailed(message));
        })
    dispatch(fetchTransactionLoading(false));
}

export const fetchAllTransactions = (): AppThunk => async (dispatch) => {
    dispatch(fetchTransactionsLoading(true))
    await http.get(`/balance/transactions`)
        .then((res) => {
            const transactions = res?.data?.data;
            dispatch(fetchTransactionsSuccess(transactions));
            // message.success("Transactions Fetched Successfully");
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(fetchTransactionsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(fetchTransactionsLoading(false));
}