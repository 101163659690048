import styled from 'styled-components';

interface LoaderProps {
    height: number;
    background?: string;
}

const LoaderWrapper = styled.div<LoaderProps>`
    display: flex;
    background: ${props => props.background || "#fff"};
    height: ${props => props.height || 300}px;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export { LoaderWrapper }