import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import http from '../../utils/api'
import { message } from 'antd'
import { fetchSingleConsult } from './consults'

export interface ExaminationError {
    message: string
}

export interface ExaminationState {
    examination: any
    examinations: Array<any>
    isExaminationsLoading: boolean
    isAddConsultExaminationsLoading: boolean
    isUpdateConsultExaminationsLoading: boolean
    isDeleteConsultExaminationsLoading: boolean
    examinationsError: ExaminationError
    addConsultExaminationsError: ExaminationError
    updateConsultExaminationsError: ExaminationError
    deleteConsultExaminationsError: ExaminationError
}

export const initialState: ExaminationState = {
    examination: null,
    examinations: [],
    isExaminationsLoading: false,
    isAddConsultExaminationsLoading: false,
    isUpdateConsultExaminationsLoading: false,
    isDeleteConsultExaminationsLoading: false,
    examinationsError: { message: '' },
    addConsultExaminationsError: { message: '' },
    updateConsultExaminationsError: { message: '' },
    deleteConsultExaminationsError: { message: '' }
}

export const examinationsSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        fetchExaminationsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isExaminationsLoading = payload
        },
        fetchExaminationsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.examinations = payload;
        },
        fetchExaminationsFailed: (state, { payload }: PayloadAction<ExaminationError>) => {
            state.examinationsError = payload;
        },
        addConsultExaminationsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isAddConsultExaminationsLoading = payload
        },
        addConsultExaminationsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.isAddExaminationsLoading = payload;
        },
        addConsultExaminationsFailed: (state, { payload }: PayloadAction<ExaminationError>) => {
            state.addConsultExaminationsError = payload;
        },
        updateConsultExaminationsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isUpdateConsultExaminationsLoading = payload
        },
        updateConsultExaminationsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.isUpdateExaminationsLoading = payload;
        },
        updateConsultExaminationsFailed: (state, { payload }: PayloadAction<ExaminationError>) => {
            state.updateConsultExaminationsError = payload;
        },
        deleteConsultExaminationsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isDeleteConsultExaminationsLoading = payload
        },
        deleteConsultExaminationsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.isdeleteExaminationsLoading = payload;
        },
        deleteConsultExaminationsFailed: (state, { payload }: PayloadAction<ExaminationError>) => {
            state.deleteConsultExaminationsError = payload;
        },
    },
})

export const { 
    fetchExaminationsLoading, fetchExaminationsSuccess, fetchExaminationsFailed,
    addConsultExaminationsLoading, addConsultExaminationsSuccess, addConsultExaminationsFailed,
    updateConsultExaminationsLoading, updateConsultExaminationsSuccess, updateConsultExaminationsFailed,
    deleteConsultExaminationsLoading, deleteConsultExaminationsSuccess, deleteConsultExaminationsFailed
} = examinationsSlice.actions;
export const examinationSelector = (state: { examinations: ExaminationState }) => state.examinations;
export default examinationsSlice.reducer;


/** Actions */

export const fetchAllExaminations = (): AppThunk => async (dispatch) => {
    dispatch(fetchExaminationsLoading(true))
    await http.get('/examinations')
        .then((res) => {
            const examinations = res?.data?.data;
            dispatch(fetchExaminationsSuccess(examinations))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message };
            dispatch(fetchExaminationsFailed(message));
        })
    dispatch(fetchExaminationsLoading(false));
}

export const addConsultExaminations = (consultId: any, payload: any): AppThunk => async (dispatch) => {
    dispatch(addConsultExaminationsLoading(true))
    await http.post(`/consults/${consultId}/examinations`, payload)
        .then((res) => {
            const examinations = res?.data?.data;
            dispatch(addConsultExaminationsSuccess(examinations))
            message.success("Examinations Added to Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(addConsultExaminationsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(addConsultExaminationsLoading(false));
}

export const updateConsultExaminations = (consultId: any, examId: any, payload: any): AppThunk => async (dispatch) => {
    dispatch(updateConsultExaminationsLoading(true))
    await http.patch(`/consults/${consultId}/examinations/${examId}`, payload)
        .then((res) => {
            const examinations = res?.data?.data;
            dispatch(updateConsultExaminationsSuccess(examinations))
            message.success("Examinations Updated in Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(updateConsultExaminationsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(updateConsultExaminationsLoading(false));
}

export const deleteConsultExaminations = (consultId: any, examIds: any): AppThunk => async (dispatch) => {
    dispatch(deleteConsultExaminationsLoading(true))
    const payload: any = {
        examinations: examIds
    }
    await http.delete(`/consults/${consultId}/examinations`, { data: payload })
        .then((res) => {
            const examinations = res?.data?.data;
            dispatch(deleteConsultExaminationsSuccess(examinations))
            message.success("Examinations Deleted from Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(deleteConsultExaminationsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(deleteConsultExaminationsLoading(false));
}

