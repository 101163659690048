import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import http from '../../utils/api'
import { fetchSingleConsult } from './consults'
import { message } from 'antd'

export interface DiagnosisError {
    message: string
}

export interface DiagnosisState {
    diagnosis: any
    diagnoses: Array<any>
    isDiagnosesLoading: boolean
    addDiagnosesLoading: boolean
    deleteDiagnosesLoading: boolean
    diagnosesError: DiagnosisError
    addDiagnosesError: DiagnosisError
    deleteDiagnosesError: DiagnosisError
}

export const initialState: DiagnosisState = {
    diagnosis: null,
    diagnoses: [],
    isDiagnosesLoading: false,
    addDiagnosesLoading: false,
    deleteDiagnosesLoading: false,
    diagnosesError: { message: '' },
    addDiagnosesError: { message: '' },
    deleteDiagnosesError: { message: '' },
}

export const diagnosesSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        fetchDiagnosesLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isDiagnosesLoading = payload
        },
        fetchDiagnosesSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.diagnoses = payload;
        },
        fetchDiagnosesFailed: (state, { payload }: PayloadAction<DiagnosisError>) => {
            state.diagnosesError = payload;
        },
        addDiagnosesLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.addDiagnosesLoading = payload
        },
        addDiagnosesSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.diagnoses = payload;
        },
        addDiagnosesFailed: (state, { payload }: PayloadAction<DiagnosisError>) => {
            state.addDiagnosesError = payload;
        },
        deleteDiagnosesLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.deleteDiagnosesLoading = payload
        },
        deleteDiagnosesSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.diagnoses = payload;
        },
        deleteDiagnosesFailed: (state, { payload }: PayloadAction<DiagnosisError>) => {
            state.deleteDiagnosesError = payload;
        },
    },
})

export const { 
    fetchDiagnosesLoading, fetchDiagnosesSuccess, fetchDiagnosesFailed, 
    addDiagnosesLoading, addDiagnosesSuccess, addDiagnosesFailed,
    deleteDiagnosesLoading, deleteDiagnosesSuccess, deleteDiagnosesFailed
} = diagnosesSlice.actions;
export const diagnosisSelector = (state: { diagnoses: DiagnosisState }) => state.diagnoses;
export default diagnosesSlice.reducer;


/** Actions */

export const fetchAllDiagnoses = (): AppThunk => async (dispatch) => {
    dispatch(fetchDiagnosesLoading(true))
    await http.get('/diagnosis')
        .then((res) => {
            const diagnoses = res?.data?.data;
            dispatch(fetchDiagnosesSuccess(diagnoses))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message };
            dispatch(fetchDiagnosesFailed(message));
        })
    dispatch(fetchDiagnosesLoading(false));
}

export const addConsultDiagnoses = (consultId: any, payload: any): AppThunk => async (dispatch) => {
    dispatch(addDiagnosesLoading(true))
    await http.post(`/consults/${consultId}/diagnosis`, payload)
        .then((res) => {
            const diagnoses = res?.data?.data;
            dispatch(addDiagnosesSuccess(diagnoses))
            message.success("Diagnosis Added to Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(addDiagnosesFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(addDiagnosesLoading(false));
}

export const deleteConsultDiagnoses = (consultId: any, diagnosesIds: any): AppThunk => async (dispatch) => {
    dispatch(deleteDiagnosesLoading(true))
    const payload: any = {
        diagnosis: diagnosesIds
    }
    await http.delete(`/consults/${consultId}/diagnosis`, { data: payload })
        .then((res) => {
            const diagnoses = res?.data?.data;
            dispatch(deleteDiagnosesSuccess(diagnoses))
            message.success("Diagnosis Deleted from Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(deleteDiagnosesFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(deleteDiagnosesLoading(false));
}

