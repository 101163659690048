import { useState } from "react";
import { Col } from "antd";
import { Link, useHistory } from "react-router-dom";
import AppIcon from "../../../assets/images/icon.svg";
import Button from "../../../component/Button";
import OtpInput from 'react-otp-input';


const EnterOTP = () => {
    const history = useHistory();
    // const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
    const [otp, setOTP] = useState("");

    return (
        <Col span={24} className="align-center">
            <div className="right-pane">
                <img className="app-logo" src={AppIcon} alt="logo" />
                <i
                    className="close-icon lni lni-close"
                    onClick={() => history.goBack()}
                />
                <div className="align-center">
                    <OtpInput
                        value={otp}
                        onChange={(val: string) => setOTP(val)}
                        numInputs={6}
                        containerStyle="otp-container"
                        inputStyle="otp-input"
                        separator={<span style={{width: '18px'}}></span>}
                    />
                    <h3>Enter one time password to continue</h3>
                    <p>Check your email or SMS for OTP that was sent to you</p>
                    <div className="align-center">
                        <Button htmlType="submit" type="primary" className="auth-button login-button">Sign in</Button>
                        <Link to="/register" className="register-link">Resend OTP</Link>
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default EnterOTP;