import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import http from '../../utils/api'
import { fetchSingleConsult } from './consults'
import { message } from 'antd'

export interface SymptomError {
    message: string
}

export interface SymptomState {
    symptom: any
    symptoms: Array<any>
    isSymptomsLoading: boolean
    isAddConsultSymptomsLoading: boolean
    isDeleteConsultSymptomLoading: boolean
    symptomsError: SymptomError
    addConsultSymptomsError: SymptomError
    deleteConsultSymptomError: SymptomError
}

export const initialState: SymptomState = {
    symptom: null,
    symptoms: [],
    isSymptomsLoading: false,
    isAddConsultSymptomsLoading: false,
    isDeleteConsultSymptomLoading: false,
    symptomsError: { message: '' },
    addConsultSymptomsError: { message: '' },
    deleteConsultSymptomError: { message: '' }
}

export const symptomsSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        fetchSymptomsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isSymptomsLoading = payload
        },
        fetchSymptomsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.symptoms = payload;
        },
        fetchSymptomsFailed: (state, { payload }: PayloadAction<SymptomError>) => {
            state.symptomsError = payload;
        },
        addConsultSymptomsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isAddConsultSymptomsLoading = payload
        },
        addConsultSymptomsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.isAddSymptomsLoading = payload;
        },
        addConsultSymptomsFailed: (state, { payload }: PayloadAction<SymptomError>) => {
            state.addConsultSymptomsError = payload;
        },
        deleteConsultSymptomLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isDeleteConsultSymptomLoading = payload
        },
        deleteConsultSymptomSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.isAddSymptomsLoading = payload;
        },
        deleteConsultSymptomFailed: (state, { payload }: PayloadAction<SymptomError>) => {
            state.deleteConsultSymptomError = payload;
        },
    },
})

export const { 
    fetchSymptomsLoading, fetchSymptomsSuccess, fetchSymptomsFailed,
    addConsultSymptomsLoading, addConsultSymptomsSuccess, addConsultSymptomsFailed,
    deleteConsultSymptomLoading, deleteConsultSymptomSuccess, deleteConsultSymptomFailed
} = symptomsSlice.actions;
export const symptomSelector = (state: { symptoms: SymptomState }) => state.symptoms;
export default symptomsSlice.reducer;


/** Actions */

export const fetchAllSymptoms = (): AppThunk => async (dispatch) => {
    dispatch(fetchSymptomsLoading(true))
    await http.get('/symptoms')
        .then((res) => {
            const symptoms = res?.data?.data;
            dispatch(fetchSymptomsSuccess(symptoms))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message };
            dispatch(fetchSymptomsFailed(message));
        })
    dispatch(fetchSymptomsLoading(false));
}

export const addConsultSymptoms = (consultId: any, payload: any): AppThunk => async (dispatch) => {
    dispatch(addConsultSymptomsLoading(true))
    await http.post(`/consults/${consultId}/symptoms`, payload)
        .then((res) => {
            const symptoms = res?.data?.data;
            dispatch(addConsultSymptomsSuccess(symptoms))
            message.success("Symptoms Added to Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(addConsultSymptomsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(addConsultSymptomsLoading(false));
}

export const deleteConsultSymptom = (consultId: any, symptomIds: any): AppThunk => async (dispatch) => {
    dispatch(deleteConsultSymptomLoading(true))
    const payload: any = {
        symptoms: symptomIds
    }
    await http.delete(`/consults/${consultId}/symptoms`, { data: payload })
        .then((res) => {
            const symptoms = res?.data?.data;
            dispatch(deleteConsultSymptomSuccess(symptoms))
            message.success("Symptoms Deleted from Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(deleteConsultSymptomFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(deleteConsultSymptomLoading(false));
}

