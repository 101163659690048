import styled from 'styled-components';

const SearchWrapper = styled.div`
    @media(max-width: 767px) {
        margin-top: 10px;
    } 
    .ant-input{
        border-radius: 6px 0px 0px 6px;
        border: 1px solid #b7c2cf;
        font-size: 13px;
        height: 40px;
    }
    .ant-input-search-button {
        width: auto;
        padding: 0px 20px;
        background: #1890ff;
        border: 1px solid #1890ff;
        border-radius: 0px 6px 6px 0px !important;
        span {
            color: #fff;
        }
    }
`;

export { SearchWrapper }