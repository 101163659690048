import { Input } from "antd";
import { SearchWrapper } from "./styles";

const AntdSearch = Input.Search;

const Search = (props: any) => {
    return (
        <SearchWrapper>
            <AntdSearch {...props} size="large" />
        </SearchWrapper>
    )
}

export default Search;