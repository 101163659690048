import { Spin } from "antd"
import { LoaderWrapper } from "./styles";

const Loader = (props: any) => {
    const { height, background } = props;
    return (
        <LoaderWrapper height={height} background={background}>
            <Spin />
        </LoaderWrapper>
    )
}

export default Loader;