import { useState } from "react";
import { Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import AppIcon from "../../../assets/images/icon.svg";
import Button from "../../../component/Button";
import Input from "../../../component/Input";
import EmailLinkSent from "./EmailLinkSent";
import ResetPasswordSvg from "../../../assets/icons/recover-password.svg";

const ResetPassword = () => {
    const history = useHistory();
    const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);

    return isLoginSuccessful 
        ? <EmailLinkSent 
            setIsLoginSuccessful={setIsLoginSuccessful} 
        /> 
        : (
        <>
            <Col xs={0} md={12}>
                <div className="left-pane">
                    <img className="app-logo" src={AppIcon} alt="logo" />
                    <div className="align-center">
                        <img src={ResetPasswordSvg} alt="icon" />
                        <h3>Reset password</h3>
                        <Row justify="center">
                            <Col span={24}>
                                <p>Enter a new password to retrieve your account</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
            <Col xs={24} md={12}>
                <div className="right-pane">
                    <span className="close-icon" onClick={() => history.goBack()}>
                        <i className="lni lni-arrow-left" /> Back
                    </span>
                    <Form
                        name="basic"
                        layout="vertical"
                        requiredMark={false}
                        onFinish={() => setIsLoginSuccessful(true)}
                    >
                        <Row gutter={24} align="middle">
                            <Col span={24}>
                                <Input
                                    formItem={{
                                        label: "Enter new password",
                                        name: "new_password",
                                        rules: [
                                            { required: true, message: 'Please input your new password' }
                                        ]
                                    }}
                                    placeholder="New password"
                                    type="password"
                                    label="Enter new password"
                                />
                            </Col>
                            <Col span={24}>
                                <Input
                                    formItem={{
                                        label: "Confirm password",
                                        name: "confirm_password",
                                        rules: [
                                            { required: true, message: 'Please input your new password' }
                                        ]
                                    }}
                                    placeholder="Confirm password"
                                    type="password"
                                    marginBottom={0}
                                    label="Confirm password"
                                />
                            </Col>
                            <Col span={24} className="align-center">
                                <Button htmlType="submit" type="primary" className="auth-button recover-password-button">
                                    Create new password
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
        </>
    )
}

export default ResetPassword;