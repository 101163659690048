import { Card as AntdCard } from "antd"
import { CardWrapper } from "./styles";

const Card = (props: any) => {
    const { padding, marginBottom, hasBoxShadow = true, ...rest } = props;
    return (
        <CardWrapper marginBottom={marginBottom} padding={padding} hasBoxShadow={hasBoxShadow}>
            <AntdCard {...rest}>
                {props.children}
            </AntdCard>
        </CardWrapper>
    )
}

export default Card;