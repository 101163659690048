import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import http from '../../utils/api'
import { fetchSingleConsult } from './consults'
import { message } from 'antd'

export interface MedicationError {
    message: string
}

export interface MedicationState {
    medication: any
    medications: Array<any>
    isMedicationsLoading: boolean
    isAddConsultMedicationsLoading: boolean
    isDeleteConsultMedicationsLoading: boolean
    medicationsError: MedicationError
    addConsultMedicationsError: MedicationError
    deleteConsultMedicationsError: MedicationError
}

export const initialState: MedicationState = {
    medication: null,
    medications: [],
    isMedicationsLoading: false,
    isAddConsultMedicationsLoading: false,    
    isDeleteConsultMedicationsLoading: false,
    medicationsError: { message: '' },
    addConsultMedicationsError: { message: '' },  
    deleteConsultMedicationsError: { message: '' }
}

export const medicationsSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        fetchMedicationsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isMedicationsLoading = payload
        },
        fetchMedicationsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.medications = payload;
        },
        fetchMedicationsFailed: (state, { payload }: PayloadAction<MedicationError>) => {
            state.medicationsError = payload;
        },
        addConsultMedicationsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isAddConsultMedicationsLoading = payload
        },
        addConsultMedicationsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.isAddMedicationsLoading = payload;
        },
        addConsultMedicationsFailed: (state, { payload }: PayloadAction<MedicationError>) => {
            state.addConsultMedicationsError = payload;
        },
        deleteConsultMedicationsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isDeleteConsultMedicationsLoading = payload
        },
        deleteConsultMedicationsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.isdeleteMedicationsLoading = payload;
        },
        deleteConsultMedicationsFailed: (state, { payload }: PayloadAction<MedicationError>) => {
            state.deleteConsultMedicationsError = payload;
        },
    },
})

export const { 
    fetchMedicationsLoading, fetchMedicationsSuccess, fetchMedicationsFailed,
    addConsultMedicationsLoading, addConsultMedicationsSuccess, addConsultMedicationsFailed,
    deleteConsultMedicationsLoading, deleteConsultMedicationsSuccess, deleteConsultMedicationsFailed
} = medicationsSlice.actions;
export const medicationSelector = (state: { medications: MedicationState }) => state.medications;
export default medicationsSlice.reducer;


/** Actions */

export const fetchAllMedications = (): AppThunk => async (dispatch) => {
    dispatch(fetchMedicationsLoading(true))
    await http.get('/medications')
        .then((res) => {
            const medications = res?.data?.data;
            dispatch(fetchMedicationsSuccess(medications))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message };
            dispatch(fetchMedicationsFailed(message));
        })
    dispatch(fetchMedicationsLoading(false));
}

export const addConsultMedications = (consultId: any, payload: any): AppThunk => async (dispatch) => {
    dispatch(addConsultMedicationsLoading(true))
    await http.post(`/consults/${consultId}/medications`, payload)
        .then((res) => {
            const medications = res?.data?.data;
            dispatch(addConsultMedicationsSuccess(medications))
            message.success("Medications Added to Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(addConsultMedicationsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(addConsultMedicationsLoading(false));
}

export const deleteConsultMedications = (consultId: any, medicationIds: any): AppThunk => async (dispatch) => {
    dispatch(deleteConsultMedicationsLoading(true))
    const payload: any = {
        medications: medicationIds
    }
    await http.delete(`/consults/${consultId}/medications`, { data: payload })
        .then((res) => {
            const medications = res?.data?.data;
            dispatch(deleteConsultMedicationsSuccess(medications))
            message.success("Medications Deleted from Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(deleteConsultMedicationsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(deleteConsultMedicationsLoading(false));
}

