import styled from 'styled-components';

const OnboardingWrapper = styled.div`
	background: #A0ADB9;
	height: 100vh;
	.full-vh {
		height: 100vh;
		padding: 113px 0px;
	}
`

const MainWrapper = styled.div`
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 3px 3px 5px #0f9af00D;
	border-radius: 5px;
	height: calc(100vh - 224px);
	border-radius: 5px;
	.inner {
		height: 100%;
		border-radius: 5px;
		background: #fff;

		.app-logo {
			position: absolute;
			top: 38px;
			left: 41px;
		}

		h3 {
			font: normal normal medium;
			font-size: 20px;
			line-height: 28px;
			letter-spacing: 0px;
			color: #002E46;
			margin-bottom: 0px;
			margin-top: 26.9px;
		}
		p {
			font: normal normal normal;
			font-size: 13px;
			line-height: 18px;
			letter-spacing: 0px;
			color: #002E46;
			margin-bottom: 0px;
		}

		.left-pane {
			padding: 38px 41px;
			background: #F3F8FB 0% 0% no-repeat padding-box;
			height: 100%;
			border-radius: 5px 0px 0px 5px;
			display: flex;
    		align-items: center;
			justify-content: center;
		}
		.terms-section-list {
			text-align: left;
			padding: 50px 25px;
			background: #F3F8FB 0% 0% no-repeat padding-box;
			height: calc(100vh - 224px);
			max-height: 100%;
			border-radius: 5px 0px 0px 5px;
			p {
				text-align: left;
				font: normal normal medium;
				font-size: 16px;
				line-height: 28px;
				letter-spacing: 0px;
				color: #0f9af0;
				margin-bottom: 20px;
			}
		}
		.right-pane {
			border-radius: 10px;
			padding: 27px 36px 27px 36px;
			background: #fff 0% 0% no-repeat padding-box;
			height: 100%;
			display: flex;
    		align-items: center;
			justify-content: center;
			&.lhs-present {
				border-radius: 0px 10px 10px 0px;
			}
			.ant-form {
				width: 100%;
			}
			.phone-input {
				.ant-input {
					margin-left: 10px;
					width: 100%;
					width: -moz-available;          /* WebKit-based browsers will ignore this. */
					width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
					width: fill-available;
				}
			}
			/* Tel Select */
			.ant-input-group-addon:first-child {
				border: none;
				border-bottom: 1px solid #BFCAD0;
			}
			.ant-form-item-has-error {
				.ant-input-group-addon:first-child {
					border: none;
					border-bottom: 1px solid crimson;
				}
			}
			.ant-select.ant-select-single.ant-select-show-arrow {
				background: #FFFFFF;
			}
			/* Phone Switch */
			.phone-switch {
				margin-top: 6px;
				text-align: left;
				font: normal normal normal 10px/13px DM Sans;
				letter-spacing: 0px;
				color: #002E46;
				.ant-switch {
					margin-right: 5px;
					height: 18px;
					width: 18px;
					.ant-switch-handle {
						width: 17px;
						height: 14px;
					}
				}
				.ant-switch-checked .ant-switch-handle {
					left: calc(100% - 17px - 2px);
				}
			}
			/* OTP Code */
			.otp-container {
				justify-content: center;
				margin-bottom: 50px;
				.otp-input {
					background: #FFFFFF 0% 0% no-repeat padding-box;
					box-shadow: 0px 0px 5px #0f9af01A;
					border: 0.800000011920929px solid #0f9af0;
					border-radius: 5px;
					width: 32px !important;
					height: 32px;
					
					/* Show green borders when valid */
					&[value=''] {
						border: 0.800000011920929px solid #002E4629;
					}
				}
			}
			/* Error Message */
			.ant-form-item-explain, .ant-form-item-extra {
				min-height: 0px;
			}
			.ant-form-item-has-error {
				.ant-input {
					border-bottom: 1px solid crimson;
				}
				.ant-input-password {
					border-bottom: 1px solid crimson;
					.ant-input {
						border: none;
					}
				}
				.ant-form-item-explain.ant-form-item-explain-error {
					color: crimson;
					text-align: left;
					font: normal normal normal 13px/14px DM Sans;
					letter-spacing: 0px;
					margin-top: 5px;
				}
			}
			.ant-form-item-label > label {
				text-align: left;
				font: normal normal normal 13px/14px DM Sans;
				letter-spacing: 0px;
				color: #0f9af0;
			}
			.fields-hidden {
				display: none;
			}
			.fields-visible {
				display: block;
			}
			/* Gender Radio Button */
			.ant-radio-group.ant-radio-group-outline {
				width: 100%;
			}
			.gender-box {
				background: #FFFFFF 0% 0% no-repeat padding-box;
				border: 1px solid #002E46;
				border-radius: 5px;
				padding: 20px 25px;
				text-align: center;
				&.active {
					background: #FFFFFF 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #0f9af026;
					border: 1px solid #0f9af0;
					border-radius: 5px;
				}
				img {
					display: block;
					margin-bottom: 16px;
					margin: 0px auto;
					margin-bottom: 16px;
				}
			}
			/* End of Gender */
			.terms-conditions-text {
				text-align: left;
				font: normal normal normal 13px/13px DM Sans;
				letter-spacing: 0px;
				color: #002E46;
				display: flex;
				align-items: center;
				.ant-checkbox {
					margin-right: 10px;
				}
			}
			.login-form-forgot {
				text-align: left;
				font: normal normal normal 13px/14px DM Sans;
				letter-spacing: 0px;
				color: #0f9af0;
				opacity: 1;
			}
			.close-icon {
				position: absolute;
				right: 36px;
				top: 27px;
				cursor: pointer;
				i {
					margin-right: 10px;
				}
			}
			.auth-button {
				border-radius: 3px;
				text-align: center;
				font: normal normal medium 13px/13px DM Sans;
				letter-spacing: 0px;
				margin-bottom: 9px;
				&.login-button {
					color: #FFFFFF;
					margin-top: 25px;
					display: block;
				}
				&.next-button {
					color: #FFFFFF;
					/* &:hover {
						background: #2699FB !important;
						color: #fff !important;
					} */
				}
				&.recover-password-button {
					margin-top: 24px;
					color: #FFFFFF;
					background: #2699FB 0% 0% no-repeat padding-box;
				}
				&.prev-button {
					margin-right: 10px !important;
				}
			}
			.justify-between {
				display: flex;
				justify-content: space-between;
			}
			.justify-end {
				display: flex;
				justify-content: flex-end;
			}
			.register-link {
				text-align: center;
				font: normal normal normal 13px/13px DM Sans;
				letter-spacing: 0px;
				color: #0f9af0;
				opacity: 1;
			}
		}
		.email-links {
			flex-direction: column;
			.bottom-text {
				position: absolute;
				width: 90%;
				border-top: 1px solid #DBDBDB;
				margin: 0px auto;
				bottom: 0px;
				padding: 17px 41px;
				text-align: center;
				font: normal normal normal 13px/50px Poppins;
				letter-spacing: 0px;
				color: #002E46;
				opacity: 1;
				a {
					margin-left: 10px;
				}
			}
		}
	}
`;

const ErrorMsg = styled.p`
	text-align: right;
	margin-top: 4px;
	font: normal normal normal 13px/14px DM Sans;
	letter-spacing: 0px;
	color: #F8204C !important;
	opacity: 1;
`

export { OnboardingWrapper, MainWrapper, ErrorMsg }