import { Col, } from "antd";

const TermsAndConditions = () => {
    return (
        <>
            <Col span={8}>
                <div className="terms-section-list">
                    <p>1. Accepting the terms</p>
                    <p>2. Health indemnity</p>
                    <p>3. Financial indemnity</p>
                    <p>4. Legal indeminity</p>
                    <p>5. Health indemnity</p>
                    <p>6. Financial indemnity</p>
                    <p>7. Legal indeminity</p>
                    <p>i. Terms and conditions</p>
                    <p>ii. Terms and conditions</p>
                    <p>iii. Terms and conditions</p>
                    <p>iv. Terms and conditions</p>
                    <p>8. Financial indemnity</p>
                    <p>9. Legal indeminity</p>
                    <p>10. Health indemnity</p>
                    <p>11. Financial indemnity</p>
                    <p>12. Legal indeminity</p>
                </div>
            </Col>
            <Col span={16}>
                <div className="right-pane lhs-present">
                    <h3>Terms & Conditions</h3>
                    
                </div>
            </Col>
        </>
    )
}

export default TermsAndConditions;