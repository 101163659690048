import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import http from '../../utils/api'

export interface EncounterError {
    message: string
}

export interface AuthState {
    encounter: any
    encounters: Array<any>
    isEncountersLoading: boolean
    encountersError: EncounterError
}

export const initialState: AuthState = {
    encounter: null,
    encounters: [],
    isEncountersLoading: false,
    encountersError: { message: '' },
}

export const encountersSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        fetchEncountersLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isEncountersLoading = payload
        },
        fetchEncountersSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.encounters = payload;
        },
        fetchEncountersFailed: (state, { payload }: PayloadAction<EncounterError>) => {
            state.encountersError = payload;
        },
    },
})

export const { fetchEncountersLoading, fetchEncountersSuccess, fetchEncountersFailed } = encountersSlice.actions;
export const encounterSelector = (state: { auth: AuthState }) => state.auth;
export default encountersSlice.reducer;


/** Actions */

export const fetchAllEncounters = (): AppThunk => async (dispatch) => {
    dispatch(fetchEncountersLoading(true))
    await http.get('/encounters')
        .then((res) => {
            const encounters= res?.data?.data;
            dispatch(fetchEncountersSuccess(encounters))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message };
            dispatch(fetchEncountersFailed(message));
        })
    dispatch(fetchEncountersLoading(false));
}

